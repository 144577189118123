import React from "react";
import { PageWrapper } from "~components/Core";
import PortfolioDetailsSection from '~sections/portfolio-details/TrustedAuthority/PortfolioDetails'
import PortfolioGridSection from '~sections/portfolio-details/TrustedAuthority/ProtfoliGrid'
import PromoSection from '~sections/portfolio-details/TrustedAuthority/PortfolioPromo'
import FooterFive from '~sections/agency/FooterFive'

import HeroSection from '~sections/portfolio-details/TrustedAuthority/Hero'

import FeatureSection from '~sections/project/trusted-authority'

import CtaSection from '~sections/it/Cta'


const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnOneText="Login"
  //     btnTwoText="Get Started For Free"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function portfolioDetailsTwo() {
  return (
    <PageWrapper innerPage={true}>
      {/* <PortfolioDetailsSection /> */}
      <HeroSection />
      <FeatureSection />
      {/* <PortfolioGridSection />
      <PromoSection /> */}
      <CtaSection />
      <FooterFive />
    </PageWrapper>
  )
}
