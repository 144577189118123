import {Images} from '~data'
const featureSectionData = {
    featureTabTitle1:"Best features<br className='d-none d-xs-block' />for your project<br className='d-none d-xs-block' /> management.",
    featureTabTitle2:"Manage your<br className='d-none d-xs-block' />tasks easily with<br className='d-none d-xs-block' /> FastLand.",
    featureTabTitle3:"Make Schedules<br className='d-none d-xs-block' />ffor Any Meeting<br className='d-none d-xs-block' /> on Calendar.",
    tabWidget1: [
        {
            icon:Images.projectManagement.featureTwoIcon1,
            iconBackground:"#ff8d52",
            title:"Manage Smartly",
            text:"Stay on top of your task lists and stay <br class='d-none d-xs-block'> in touch with what's happening",
        },
        {
            icon:Images.projectManagement.featureTwoIcon2,
            iconBackground:"#15cda8",
            title:"Communicate Fast",
            text:"Stay on top of your task lists and stay <br class='d-none d-xs-block'> in touch with what's happening",
        },
    ],
    

}

export default featureSectionData;