import { Images } from '~data'
const featureSectionData = {
    featureTabTitle: "",
    tabWidget: [
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "Device Life Cycles Management",
        },
        {
            icon: Images.projectManagement.featureTwoIcon2,
            iconBackground: "#15cda8",
            title: "Manage Device Crypto Asserts",
        },
        {
            icon: Images.projectManagement.featureTwoIcon1,
            iconBackground: "#ff8d52",
            title: "Device Middleware Updates",
        },
        {
            icon: Images.projectManagement.featureTwoIcon2,
            iconBackground: "#15cda8",
            title: "Monitoring",
        },
    ],


}

export default featureSectionData;